import React from "react";

const Skills = () => {
	return (
		<div className='section skills' id='skills'>
			<h3 className='lead'>
				{" "}
				<strong> SKILLS</strong>
			</h3>
			<div className='content-skills cont'>
				<div className='row '>
					<div className=' skill'>
						<i className='fab fa-html5'></i>

						<small>HTML</small>
					</div>

					<div className='skill'>
						<i className='fab fa-css3-alt'></i>

						<small>CSS</small>
					</div>

					<div className='skill'>
						<i className='fab fa-js-square'></i>

						<small>JavaScript</small>
					</div>

					<div className='skill'>
						<i className='fab fa-linux'></i>

						<small>Linux</small>
					</div>
					<div className='skill'>
						<i className='fab fa-react'></i>

						<small>React</small>
					</div>

					<div className='skill'>
						<i className='fab fa-rectangle-ad'>XD</i>

						<small>Adobe XD</small>
					</div>

					<div className='skill'>
						<i className='fab fa-python'></i>

						<small>Python</small>
					</div>

					<div className='skill '>
						<i className='fab fa-bootstrap'></i>

						<small>Bootstrap</small>
					</div>

					<div className='skill'>
						<i class='fab fa-sass'></i>

						<small>SASS</small>
					</div>

					<div className='skill'>
						<i className='fab fa-git-square'></i>

						<small>GIT</small>
					</div>
					<div className='skill'>
						<i>TS</i>

						<small>TypeScript</small>
					</div>
					<div className='skill'>
						<i className='fas fa-fire'></i>

						<small>Firebase</small>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Skills;
