import React from "react";
import ProjectCard from "./ProjectCard";

const Project = () => {
	const projectArray = [
		{
			name: "TravLocally",
			link: "https://travlocally-34376.web.app/",
			tech: "React, Firebase, SCSS",
		},
		{
			name: "CalcGo",
			link: "https://www.calcgo.com",
			tech: "Next js, Firebase, MUI",
		},
		{
			name: "Sathi NY",
			link: "https://6286d6c8ae82170009b2ae34--extraordinary-medovik-325e69.netlify.app/",
			tech: "React, Firebase, SCSS",
		},
		{
			name: "Mt Everest Grocery",
			link: "https://www.mteverestgrocery.com/",
			tech: "React, Firebase, SCSS",
		},
		{
			name: "RedPepper Express",
			link: "https://redpepperxpress.com/",
			tech: "React, Firebase",
		},

		{
			name: "Director Portfolio",
			link: "https://hopeful-bell-d95bc4.netlify.app/",
			tech: "React, Bootstrap",
		},
		{
			name: "DataGurkhas",
			link: "https://datagurkhas.com/",
			tech: "React, Bootstrap",
		},
		{
			name: "Portfolio V1",
			link: "https://app.netlify.com/sites/elastic-bhabha-833ffe/overview",
			tech: "React, Bootstrap",
		},

		{
			name: "Portfolio V2",
			link: "https://62d248810eebee476f9d453f--phenomenal-puffpuff-366747.netlify.app/",
			tech: "React, Bootstrap",
		},
	];
	return (
		<div className='section projects'>
			<h3 className='lead'>
				<strong> Projects</strong>
			</h3>
			<div className='project-cards'>
				{projectArray.map((project) => (
					<ProjectCard project={project} />
				))}
			</div>
		</div>
	);
};

export default Project;
