import React, { useEffect, useState } from "react";

const Drop = () => {
	const [paint, setPaint] = useState({});
	useEffect(() => {
		getVar();
	}, []);
	const getVar = () => {
		setPaint({
			posX: Math.random() * window.innerWidth,
			delay: Math.random() * -20,
			duration: Math.random() * 10,
			width: Math.random() * 3,
			height: Math.random() * 100,
		});
	};
	const myStyle = {
		width: `${paint.width}px`,
		height: `${paint.height}px`,
		animationDuration: `${paint.duration}s`,
		animationDelay: `${paint.delay}s`,
		left: `${paint.posX}px`,
	};

	return <i style={myStyle}></i>;
};

export default Drop;
