import React, { useEffect, useRef, useState } from "react";
import Contact from "./Contact";
import Landing from "./Landing";
import Project from "./Project";
import Rain from "./Rain";
import Skills from "./Skills";

function Home() {
	const myRef0 = useRef();
	const myRef1 = useRef();
	const myRef2 = useRef();
	const myRef3 = useRef();

	const [currentPage, setCurrentPage] = useState(myRef0);
	const [track, setTrack] = useState(0);
	const [exc, setExc] = useState(false);

	window.onscroll = function () {
		exc === false && currentPage.current.scrollIntoView();
		console.log("ran");
	};

	const renderPage = (operation) => {
		let tracNum = operation === "add" ? track + 1 : track - 1;
		console.log(tracNum);
		setExc(true);
		setTrack(tracNum);

		if (tracNum === 1) {
			myRef1.current.scrollIntoView({ behavior: "smooth" });
			setCurrentPage(myRef1);
		} else if (tracNum === 2) {
			myRef2.current.scrollIntoView({ behavior: "smooth" });
			setCurrentPage(myRef2);
		} else if (tracNum === 3) {
			myRef3.current.scrollIntoView({ behavior: "smooth" });
			setCurrentPage(myRef3);
		} else if (tracNum === 0) {
			myRef0.current.scrollIntoView({ behavior: "smooth" });
			setCurrentPage(myRef0);
		} else {
			myRef0.current.scrollIntoView(false);
			setCurrentPage(myRef0);
		}
	};

	return (
		<div className='App'>
			<Rain />
			<div ref={myRef0}>
				<Landing />
			</div>

			<div ref={myRef1}>
				<Skills />
			</div>
			<div ref={myRef2}>
				<Project />
			</div>
			<div ref={myRef3}>
				<Contact />
			</div>
			{track <= 2 && (
				<div
					className='next'
					onClick={async () => {
						await renderPage("add");
						setTimeout(() => {
							setExc(false);
						}, 500);
					}}
				>
					<i className='fas fa-angle-right'></i>
				</div>
			)}

			{track > 0 && (
				<div
					className='next prev'
					onClick={async () => {
						await renderPage("subtract");
						setTimeout(() => {
							setExc(false);
						}, 500);
					}}
				>
					<i className='fas fa-angle-left'></i>
				</div>
			)}
		</div>
	);
}

export default Home;
