import React, { useState } from "react";
import Drop from "./Drop";

const Rain = () => {
	const [amount, setAmount] = useState(40);
	const rain = () => {
		// let i = 0;
		// let body = document.querySelector(".rain");
		// while (i < amount) {
		// 	let drop = document.createElement("i");
		// 	let posX = Math.random() * window.innerWidth;
		// 	let delay = Math.random() * -20;
		// 	let duration = Math.random() * 10;
		// 	let width = Math.random() * 3;
		// 	let height = Math.random() * 100;
		// 	drop.style.width = `${width}px`;
		// 	drop.style.height = `${height}px`;
		// 	drop.style.animationDuration = `${duration}s`;
		// 	drop.style.animationDelay = `${delay}s`;
		// 	drop.style.left = `${posX}px`;
		// 	body.appendChild(drop);
		// 	i++;
		// }
	};
	rain();
	return (
		<div className='rain'>
			{[...Array(amount)].map((e, i) => (
				<Drop key={i} />
			))}
		</div>
	);
};

export default Rain;
