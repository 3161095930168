import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Resume from "./Resume";

function App() {
	return (
		<div>
			<Router>
				<Routes>
					<Route exact path='/' element={<Home />} />
					<Route exact path='/resume' element={<Resume />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
