import React from "react";
import Hero from "./images/hero.gif";
import Post from "./images/post.png";

const Landing = () => {
	return (
		<div className='landing section'>
			<div className='landing-content'>
				<img src={Hero} alt='' />
				<div className='text'>
					<h1>WHO AM I?</h1>
					<h2>
						A FrontEnd Software Engineer based in San Francisco. Who Just loves
						to code.
					</h2>
					<p className='rule'>I live by 3 C's. Coffee, Computer, and Code!</p>
				</div>
				<div className='post'>
					<img src={Post} alt='' />
				</div>
			</div>
		</div>
	);
};

export default Landing;
