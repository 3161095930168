import React, { useState } from "react";
import DocPdf from "./DocPdf";

const Resume = () => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	return (
		<div>
			<p>Not Yet Available!</p>
		</div>
	);
};

export default Resume;
