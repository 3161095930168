import React from "react";

const ProjectCard = ({ project }) => {
	return (
		<div className='project-card'>
			<h4>{project.name}</h4>
			<div className='texts'>
				<p>{project.tech}</p>
			</div>
			<a href={project.link} target='blink' className='btn-primary'>
				Visit Site
			</a>
		</div>
	);
};

export default ProjectCard;
